<template>
  <div id="login" class="d-flex align-center">
    <v-container>
          <v-card class="login-form">
            <v-card-text>
              <v-row>
                <v-col class="title" cols="12" md="6">
                  <img src="@/assets/logo2022_v.svg" alt="TOKOTON">
                </v-col>
                <v-col cols="12" md="6" class="px-8">
                  <v-spacer class="d-none d-md-flex"></v-spacer>
                  <p class="ma-0 text-center">この共有リンクはパスワードで保護されています。</p>
                  <v-form ref="form" v-model="valid" class="pa-2 text-center">
                    <v-text-field :disabled="loading" label="閲覧パスワード" :type="showPassword ? 'text' : 'password'" autocomplete="current-password" id="current-password" v-model="password" :rules="passwordRules" required class="mb-3" prepend-icon="mdi-lock-check" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" @keyup.native.enter="submit" autofocus></v-text-field>
                    <v-btn :loading="loading" :disabled="!valid" @click="submit" color="primary" rounded large>ログイン</v-btn>
                  </v-form>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
    </v-container>
  </div>
</template>

<script>
import firebase from '@/plugins/firebase'
import firebaseError from '@/plugins/firebase/error'
import axios from '@/plugins/axios'

export default {
  name: 'LoginDirection',
  data: function () {
    return {
      loading: false,
      valid: false,
      password: '',
      passwordRules: [
        v => !!v || 'パスワードの入力をしてください',
        v => v.length >= 6 || 'パスワードは6文字以上を入力してください'
      ],
      showPassword: false
    }
  },
  methods: {
    submit () {
      const _self = this
      if (this.$refs.form.validate()) {
        _self.loading = true
        _self.userLoginAnonymously()
        // 認証エラー頻発によるアクセス拒否問題を軽減
        setTimeout(function () {
          if (!_self.$store.getters.loginUserInfo.id) {
            _self.password = ''
          }
          _self.loading = false
        }, 3000)
      }
    },
    userLoginAnonymously () {
      const self = this
      firebase
        .auth()
        .signInAnonymously()
        .then(async user => {
          const formData = new FormData()
          formData.append('_method', 'POST')
          formData.append('direction_id', self.$route.params.id)
          formData.append('guest_password', self.password)
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/login/direction/signup`, formData)
            .then(() => {
              self.$router.push({ name: 'guest:direction_show', params: { id: self.$route.params.id } })
            })
            .catch(() => {
              self.$store.commit('setFlashError', '閲覧パスワードが正しくありません。')
            })
        })
        .catch(err => {
          self.$store.commit('setFlashError', firebaseError(err, 'signin'))
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  #login{
    height: 100%;
    background: #c6d7da;
    // background: linear-gradient(105deg, #4ab4d8 40%, #99d871 60%);
    .row{
      height: 100%;
      align-items: stretch;
      & > *{
        min-height: 400px;
        flex-direction: column;
        display: flex;
        justify-content: center;
        &:first-child{
          align-items: center;
        }
      }

    }

    .title{
      background-color: #eee;
      padding: 0;
      border-radius: 10px 0 0 10px;
      img{
        max-width: 200px;
      }

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        border-radius: 10px 10px 0 0;
      }
    }
    .login-form{
      background-color: #ffffffdd;
      box-shadow: 0 0 40px #00000033!important;
      border: 1px solid #fff;
      border-radius: 20px!important;
      // backdrop-filter: blur(10px);
    }

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        .container{
          max-width: 900px;
        }
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .row{
          & > *{
              min-height: 200px;
          }
        }
        .title{
          img{
            max-width: 150px;
          }
        }
    }
  }

</style>
